@function multiple-box-shadow($n) {
  $value: "#{random(1600)}px #{random(20000)}px #999";

  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #999";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.stars {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;

    > * {
        background: transparent;
        &:after {
          content: " ";
          position: absolute;
          top: 2000px;
          background: transparent;
        }
    }

    > *:nth-child(1) {
        width: 1px;
        height: 1px;
        box-shadow: $shadows-small;
        animation: animStar 50s linear infinite;
      
        &:after {
          width: 1px;
          height: 1px;
          box-shadow: $shadows-small;
        }
    }

    > *:nth-child(2) {
        width: 2px;
        height: 2px;
        box-shadow: $shadows-medium;
        animation: animStar 100s linear infinite;
      
        &:after {
          width: 2px;
          height: 2px;
          box-shadow: $shadows-medium;
        }
    }

    > *:nth-child(3) {
        width: 3px;
        height: 3px;
        box-shadow: $shadows-big;
        animation: animStar 150s linear infinite;
      
        &:after {
          width: 3px;
          height: 3px;
          box-shadow: $shadows-big;
        }
    }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
